/* components/Navbar.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { notesStored } from '/stores.js';
import { title } from '/stores.js';
import { downloadJson, uploadJson } from '/jsonUtils.js';
import { onMount } from 'svelte';
import Modal from './Modal.svelte';

function create_default_slot(ctx) {
	let h2;
	let t1;
	let hr0;
	let t2;
	let p0;
	let t4;
	let p1;
	let t6;
	let p2;
	let t8;
	let p3;
	let t10;
	let hr1;
	let t11;
	let p4;
	let t13;
	let p5;
	let t15;
	let hr2;
	let t16;
	let p6;
	let t18;
	let hr3;
	let t19;
	let p7;
	let t22;
	let p8;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Ypsilon is simple note taking app";
			t1 = space();
			hr0 = element("hr");
			t2 = space();
			p0 = element("p");
			p0.textContent = "You can add notes, every note have title and content fields";
			t4 = space();
			p1 = element("p");
			p1.textContent = "Contents of both is html. so you can paste formatted content here";
			t6 = space();
			p2 = element("p");
			p2.textContent = "Notes are persistent via local storage, so if you reload page or open new tab - you still have your notes";
			t8 = space();
			p3 = element("p");
			p3.textContent = "You can download/upload notes in form of json";
			t10 = space();
			hr1 = element("hr");
			t11 = space();
			p4 = element("p");
			p4.textContent = "its a client side app, so no server needed, it works offline";
			t13 = space();
			p5 = element("p");
			p5.textContent = "so its free forever, as it does not store anything";
			t15 = space();
			hr2 = element("hr");
			t16 = space();
			p6 = element("p");
			p6.textContent = "Ypsilon is made for fast notes and meant to be so simple, you dont even see its there.";
			t18 = space();
			hr3 = element("hr");
			t19 = space();
			p7 = element("p");
			p7.innerHTML = `made by: <a href="https://agamurian.com">agamurian</a>`;
			t22 = space();
			p8 = element("p");
			p8.innerHTML = `builded with svelte and parcel, open source: <a href="https://github.com/agamurian/ypsilon">github</a>`;
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, hr0, anchor);
			insert(target, t2, anchor);
			insert(target, p0, anchor);
			insert(target, t4, anchor);
			insert(target, p1, anchor);
			insert(target, t6, anchor);
			insert(target, p2, anchor);
			insert(target, t8, anchor);
			insert(target, p3, anchor);
			insert(target, t10, anchor);
			insert(target, hr1, anchor);
			insert(target, t11, anchor);
			insert(target, p4, anchor);
			insert(target, t13, anchor);
			insert(target, p5, anchor);
			insert(target, t15, anchor);
			insert(target, hr2, anchor);
			insert(target, t16, anchor);
			insert(target, p6, anchor);
			insert(target, t18, anchor);
			insert(target, hr3, anchor);
			insert(target, t19, anchor);
			insert(target, p7, anchor);
			insert(target, t22, anchor);
			insert(target, p8, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(hr0);
			if (detaching) detach(t2);
			if (detaching) detach(p0);
			if (detaching) detach(t4);
			if (detaching) detach(p1);
			if (detaching) detach(t6);
			if (detaching) detach(p2);
			if (detaching) detach(t8);
			if (detaching) detach(p3);
			if (detaching) detach(t10);
			if (detaching) detach(hr1);
			if (detaching) detach(t11);
			if (detaching) detach(p4);
			if (detaching) detach(t13);
			if (detaching) detach(p5);
			if (detaching) detach(t15);
			if (detaching) detach(hr2);
			if (detaching) detach(t16);
			if (detaching) detach(p6);
			if (detaching) detach(t18);
			if (detaching) detach(hr3);
			if (detaching) detach(t19);
			if (detaching) detach(p7);
			if (detaching) detach(t22);
			if (detaching) detach(p8);
		}
	};
}

function create_fragment(ctx) {
	let nav;
	let nav_title;
	let t0;
	let separator;
	let t1;
	let nav_item0;
	let t3;
	let nav_item1;
	let t5;
	let nav_item2;
	let t6;
	let t7;
	let t8;
	let t9;
	let input;
	let t10;
	let nav_item3;
	let t12;
	let modal;
	let current;
	let mounted;
	let dispose;

	modal = new Modal({
			props: {
				isOpen: /*showModal*/ ctx[2],
				onClose: /*closeModal*/ ctx[7],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			nav = element("nav");
			nav_title = element("nav-title");
			t0 = space();
			separator = element("separator");
			t1 = space();
			nav_item0 = element("nav-item");
			nav_item0.textContent = "about";
			t3 = space();
			nav_item1 = element("nav-item");
			nav_item1.textContent = "clear";
			t5 = space();
			nav_item2 = element("nav-item");
			t6 = text("Get ");
			t7 = text(/*$title*/ ctx[0]);
			t8 = text(".json");
			t9 = space();
			input = element("input");
			t10 = space();
			nav_item3 = element("nav-item");
			nav_item3.textContent = "Set";
			t12 = space();
			create_component(modal.$$.fragment);
			set_custom_element_data(nav_title, "contenteditable", "true");
			set_custom_element_data(nav_title, "class", "svelte-1e21cau");
			if (/*$title*/ ctx[0] === void 0) add_render_callback(() => /*nav_title_input_handler*/ ctx[8].call(nav_title));
			attr(separator, "class", "svelte-1e21cau");
			set_custom_element_data(nav_item0, "class", "svelte-1e21cau");
			set_custom_element_data(nav_item1, "class", "svelte-1e21cau");
			set_custom_element_data(nav_item2, "class", "svelte-1e21cau");
			attr(input, "type", "file");
			attr(input, "accept", ".json");
			set_custom_element_data(nav_item3, "class", "svelte-1e21cau");
			attr(nav, "class", "svelte-1e21cau");
		},
		m(target, anchor) {
			insert(target, nav, anchor);
			append(nav, nav_title);

			if (/*$title*/ ctx[0] !== void 0) {
				nav_title.innerHTML = /*$title*/ ctx[0];
			}

			append(nav, t0);
			append(nav, separator);
			append(nav, t1);
			append(nav, nav_item0);
			append(nav, t3);
			append(nav, nav_item1);
			append(nav, t5);
			append(nav, nav_item2);
			append(nav_item2, t6);
			append(nav_item2, t7);
			append(nav_item2, t8);
			append(nav, t9);
			append(nav, input);
			append(nav, t10);
			append(nav, nav_item3);
			insert(target, t12, anchor);
			mount_component(modal, target, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(nav_title, "input", /*nav_title_input_handler*/ ctx[8]),
					listen(nav_item0, "click", /*openModal*/ ctx[6]),
					listen(nav_item1, "click", /*clear*/ ctx[5]),
					listen(nav_item2, "click", /*getJson*/ ctx[3]),
					listen(input, "change", /*change_handler*/ ctx[9]),
					listen(nav_item3, "click", /*setJson*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$title*/ 1 && /*$title*/ ctx[0] !== nav_title.innerHTML) {
				nav_title.innerHTML = /*$title*/ ctx[0];
			}

			if (!current || dirty & /*$title*/ 1) set_data(t7, /*$title*/ ctx[0]);
			const modal_changes = {};
			if (dirty & /*showModal*/ 4) modal_changes.isOpen = /*showModal*/ ctx[2];

			if (dirty & /*$$scope*/ 1024) {
				modal_changes.$$scope = { dirty, ctx };
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(nav);
			if (detaching) detach(t12);
			destroy_component(modal, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function removeHtmlTags(str) {
	return str.replace(/<[^>]*>/g, '');
}

function instance($$self, $$props, $$invalidate) {
	let $title;
	component_subscribe($$self, title, $$value => $$invalidate(0, $title = $$value));
	let jsonFile;

	const getJson = () => {
		notesStored.subscribe(value => {
			downloadJson(value, $title + '.json');
		})();
	};

	const setJson = () => {
		if (jsonFile) {
			uploadJson(jsonFile, data => {
				notesStored.set(data);
				title.set(jsonFile.name.replace('.json', ''));
			});
		}
	};

	const clear = () => {
		notesStored.set([]);
	};

	let showModal = false;

	const openModal = () => {
		$$invalidate(2, showModal = true);
	};

	const closeModal = () => {
		$$invalidate(2, showModal = false);
	};

	function nav_title_input_handler() {
		$title = this.innerHTML;
		title.set($title);
	}

	const change_handler = e => $$invalidate(1, jsonFile = e.target.files[0]);

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$title*/ 1) {
			$: {
				$title;

				if ($title.includes('<') && $title.includes('>')) {
					title.set(removeHtmlTags($title));
				}

				console.log($title);
			}
		}
	};

	return [
		$title,
		jsonFile,
		showModal,
		getJson,
		setJson,
		clear,
		openModal,
		closeModal,
		nav_title_input_handler,
		change_handler
	];
}

class Navbar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Navbar;