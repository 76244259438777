/* components/App.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Notes from '~/components/Notes.svelte';
import Navbar from '~/components/Navbar.svelte';

function create_fragment(ctx) {
	let title_value;
	let t0;
	let div;
	let notes;
	let t1;
	let navbar;
	let current;
	document.title = title_value = /*appName*/ ctx[0];
	notes = new Notes({});
	navbar = new Navbar({});

	return {
		c() {
			t0 = space();
			div = element("div");
			create_component(notes.$$.fragment);
			t1 = space();
			create_component(navbar.$$.fragment);
			attr(div, "class", "appRoot");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, div, anchor);
			mount_component(notes, div, null);
			append(div, t1);
			mount_component(navbar, div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*appName*/ 1) && title_value !== (title_value = /*appName*/ ctx[0])) {
				document.title = title_value;
			}
		},
		i(local) {
			if (current) return;
			transition_in(notes.$$.fragment, local);
			transition_in(navbar.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(notes.$$.fragment, local);
			transition_out(navbar.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(div);
			destroy_component(notes);
			destroy_component(navbar);
		}
	};
}

const name = 'Svelte';

function instance($$self, $$props, $$invalidate) {
	let { appName = 'Ypsilon' } = $$props;

	$$self.$$set = $$props => {
		if ('appName' in $$props) $$invalidate(0, appName = $$props.appName);
	};

	return [appName];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { appName: 0 });
	}
}

export default App;