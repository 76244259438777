/* components/Notes.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	space
} from "svelte/internal";

import { notesStored } from '/stores.js';
import { title } from '/stores.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	child_ctx[6] = list;
	child_ctx[7] = i;
	return child_ctx;
}

// (104:2) {#each $notesStored as note}
function create_each_block(ctx) {
	let div1;
	let h4;
	let t;
	let div0;
	let p;
	let mounted;
	let dispose;

	function h4_input_handler() {
		/*h4_input_handler*/ ctx[2].call(h4, /*each_value*/ ctx[6], /*note_index*/ ctx[7]);
	}

	function p_input_handler() {
		/*p_input_handler*/ ctx[3].call(p, /*each_value*/ ctx[6], /*note_index*/ ctx[7]);
	}

	return {
		c() {
			div1 = element("div");
			h4 = element("h4");
			t = space();
			div0 = element("div");
			p = element("p");
			attr(h4, "class", "fira-sans svelte-iu4t39");
			attr(h4, "contenteditable", "true");
			if (/*note*/ ctx[5]['title'] === void 0) add_render_callback(h4_input_handler);
			attr(p, "class", "code fira-code svelte-iu4t39");
			attr(p, "contenteditable", "true");
			if (/*note*/ ctx[5]['content'] === void 0) add_render_callback(p_input_handler);
			attr(div0, "class", "box svelte-iu4t39");
			attr(div1, "class", "box svelte-iu4t39");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, h4);

			if (/*note*/ ctx[5]['title'] !== void 0) {
				h4.innerHTML = /*note*/ ctx[5]['title'];
			}

			append(div1, t);
			append(div1, div0);
			append(div0, p);

			if (/*note*/ ctx[5]['content'] !== void 0) {
				p.innerHTML = /*note*/ ctx[5]['content'];
			}

			if (!mounted) {
				dispose = [
					listen(h4, "input", h4_input_handler),
					listen(p, "input", p_input_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$notesStored*/ 1 && /*note*/ ctx[5]['title'] !== h4.innerHTML) {
				h4.innerHTML = /*note*/ ctx[5]['title'];
			}

			if (dirty & /*$notesStored*/ 1 && /*note*/ ctx[5]['content'] !== p.innerHTML) {
				p.innerHTML = /*note*/ ctx[5]['content'];
			}
		},
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div1;
	let t0;
	let div0;
	let mounted;
	let dispose;
	let each_value = /*$notesStored*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t0 = space();
			div0 = element("div");
			div0.textContent = "+ add note";
			attr(div0, "class", "box fira-sans button svelte-iu4t39");
			attr(div1, "class", "helloSvelte");
			attr(div2, "class", "container svelte-iu4t39");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			append(div1, t0);
			append(div1, div0);

			if (!mounted) {
				dispose = listen(div0, "click", /*addNote*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$notesStored*/ 1) {
				each_value = /*$notesStored*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, t0);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $notesStored;
	component_subscribe($$self, notesStored, $$value => $$invalidate(0, $notesStored = $$value));

	let notes = [
		{ 'title': 'title', 'content': 'content' },
		{ 'title': 'title', 'content': 'content' }
	];

	function addNote() {
		let newNotes = $notesStored;
		newNotes.push({ 'title': '', 'content': '' });
		notesStored.set(newNotes);
		return newNotes;
	}

	function h4_input_handler(each_value, note_index) {
		each_value[note_index]['title'] = this.innerHTML;
		notesStored.set($notesStored);
	}

	function p_input_handler(each_value, note_index) {
		each_value[note_index]['content'] = this.innerHTML;
		notesStored.set($notesStored);
	}

	return [$notesStored, addNote, h4_input_handler, p_input_handler];
}

class Notes extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Notes;