/* components/Modal.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	bubble,
	check_outros,
	create_slot,
	detach,
	element,
	empty,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert,
	listen,
	run_all,
	safe_not_equal,
	space,
	stop_propagation,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

function create_if_block(ctx) {
	let div1;
	let div0;
	let t0;
	let button;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (default_slot) default_slot.c();
			t0 = space();
			button = element("button");
			button.textContent = "Close";
			attr(button, "class", "svelte-1dmg625");
			attr(div0, "class", "modal-content svelte-1dmg625");
			attr(div1, "class", "modal-overlay svelte-1dmg625");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			append(div0, t0);
			append(div0, button);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button, "click", /*closeModal*/ ctx[1]),
					listen(div0, "click", stop_propagation(/*click_handler*/ ctx[5])),
					listen(div1, "click", /*closeModal*/ ctx[1])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*isOpen*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*isOpen*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*isOpen*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { isOpen = false } = $$props;
	let { onClose } = $$props;

	const closeModal = () => {
		$$invalidate(0, isOpen = false);

		if (onClose) {
			onClose();
		}
	};

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('isOpen' in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ('onClose' in $$props) $$invalidate(2, onClose = $$props.onClose);
		if ('$$scope' in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	return [isOpen, closeModal, onClose, $$scope, slots, click_handler];
}

class Modal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, onClose: 2 });
	}
}

export default Modal;